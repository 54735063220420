import React from 'react';
import ReactPaginate from 'react-paginate-accessible';

import './MusicVideoList.scss';

class MusicVideoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      musicVideo: [],
      metadata: [],
      pageCount: 1,
      limit: 6,
      start: 0,
      end: 6
    };

    this.setPageCount = this.setPageCount.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  setPageCount() {
    let pages = Math.ceil(this.state.musicVideo.length / this.state.limit);
    this.setState({
      pageCount: pages
    });
  }

  handlePageClick(data) {
    const limit = this.state.limit;
    let start = Math.floor(data.selected * limit);
    let end = start + limit;
    this.setState({
      start: start,
      end: end
    });
  }

  componentDidMount() {
    this.setPageCount();
    this.mounted = true;

    fetch('https://stephengrahamprojects.com/backend/wp-json/api/v1/music')
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            musicVideo: result
          });
        },
        error => {
          this.setState({
            error
          });
        }
      );
  }

  render() {
    const { error, musicVideo } = this.state;

    const music = musicVideo.slice(this.state.start, this.state.end) || [];

    let musicVideoMarkup;

    if (music.length > 0) {
      musicVideoMarkup = music.map((musicVideoData, i) => {
        const { title, slug, thumbnail } = musicVideoData;

        return (
          <div key={i} className="col-md-6 mb-4">
            <a href={`/music/${slug}`}>
              <img
                className="w-100 mb-3"
                src={thumbnail}
                alt={`Thumbnail for ${title}`}
              />
              <h3>{title}</h3>
            </a>
          </div>
        );
      });
    } else {
      musicVideoMarkup = null;
    }

    if (error) {
      console.warn('Error fetching films');
    } else {
      console.log('films fetched');
      return (
        <div className="container-fluid music-video-directory px-0">
          <div className="container py-5">
            <h2>All Music Videos</h2>
            <div className="row py-5">{musicVideoMarkup}</div>
          </div>
          {musicVideo && musicVideo.length > this.state.limit && (
            <div className="col-md-12 text-center">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                pageClassName={'page-item'}
                breakClassName={'break-me'}
                breakLabel={'...'}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={4}
                activeClassName={'active'}
                onPageChange={this.handlePageClick}
                accessibleText="Go to page"
                accessibleClass="sr-only"
              />
            </div>
          )}
        </div>
      );
    }
  }
}

export default MusicVideoList;
