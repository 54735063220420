// Code Credit https://github.com/PenguinOfWar

import React from 'react';
import classNames from 'classnames';

import './Icon.scss';

const Icon = (props = {}) => {
  const { type, icon, alt, ...theRest } = props;

  let iconRender;

  switch (type) {
    case 'fa':
    case 'icon':
      iconRender = (
        <i
          className={classNames({
            [type]: type,
            [icon]: icon
          })}
          aria-hidden="true"
          {...theRest}
        >
          <span className="sr-only">{alt}</span>
        </i>
      );

      break;
    case 'img':
      iconRender = (
        <img className="img-icon" src={icon} alt={alt} {...theRest} />
      );

      break;
    default:
      iconRender = null;
  }

  return iconRender;
};

export default Icon;
