import React from 'react';
import classNames from 'classnames';
import Equalizer from 'react-equalizer';

import './TextImageColumns.scss';

const TextImageColumns = props => {
  const { data = {} } = props;
  const {
    title,
    subTitle,
    content,
    alignment,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText,
    columns = [],
    removeTopPadding,
    removeBottomPadding,
    functionalHeader,
    functionalSubheader,
    titleHeaderTag,
    subtitleHeaderTag
  } = data;

  const columnsMarkup = columns.length
    ? columns.map((column, i) => {
        const {
          title,
          subTitle,
          alignment,
          thumbnail,
          altText,
          content,
          button = {}
        } = column;

        const { name, link } = button;
        const length = columns.length;

        return (
          <div
            key={i}
            className={classNames({
              'text-column': true,
              'col-md-12': length <= 1,
              'col-md-6': length === 2,
              'col-md-4': length === 3,
              'col-md-3': length >= 4,
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
              'pb-4': true
            })}
          >
            {thumbnail && (
              <div className="text-column-image">
                <picture>
                  <img
                    src={thumbnail}
                    alt={altText ? altText : ''}
                    aria-hidden={altText ? 'false' : 'true'}
                    className={classNames({
                      'img-fluid': true
                    })}
                  />
                </picture>
              </div>
            )}
            {title !== '' && (
              <h3
                className={classNames({
                  'dynamic-header': true,
                  'pt-2': true
                })}
              >
                {title}
              </h3>
            )}
            {subTitle !== '' && (
              <h4
                className={classNames({
                  'dynamic-header': true
                })}
              >
                {subTitle}
              </h4>
            )}
            {content !== '' && (
              <div
                className="column-inner-content inner-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {button && (
              <a href={link}>
                <button
                  key={i}
                  className={classNames({
                    btn: true,
                    'btn-dark': isDarkText === true,
                    'w-100': true,
                    'mt-2': true,
                    'mx-0': true
                  })}
                >
                  {name}
                </button>
              </a>
            )}
          </div>
        );
      })
    : null;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    color: textColor
  };

  const HeaderElement = titleHeaderTag ? titleHeaderTag : 'h1';
  const SubHeaderElement = subtitleHeaderTag ? subtitleHeaderTag : 'h2';

  return (
    <div className="container-fluid text-image-columns" style={inlineStyle}>
      <div
        className={classNames({
          container: true,
          'pt-5': removeTopPadding !== true,
          'pb-5': removeBottomPadding !== true,
          'pt-0': removeTopPadding === true,
          'pb-0': removeBottomPadding === true
        })}
      >
        <div className="row px-0">
          <div
            className={classNames({
              'col-md-12': true,
              'p-0': true,
              'pb-2': true,
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
              'dark-text': isDarkText === true
            })}
          >
            {functionalHeader === true ? (
              <HeaderElement>{title}</HeaderElement>
            ) : (
              <div className={HeaderElement}>{title}</div>
            )}
            {functionalSubheader === true ? (
              <SubHeaderElement>{subTitle}</SubHeaderElement>
            ) : (
              <div className={SubHeaderElement}>{subTitle}</div>
            )}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        <Equalizer className="row px-0">{columnsMarkup}</Equalizer>
      </div>
    </div>
  );
};

export default TextImageColumns;
