import React from 'react';
import classNames from 'classnames';

import './Testimonials.scss';

const Testimonials = props => {
  const { data = {} } = props;
  const {
    title,
    subTitle,
    content,
    alignment,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText,
    rows = [],
    removeTopPadding,
    removeBottomPadding,
    functionalHeader,
    functionalSubheader,
    titleHeaderTag,
    subtitleHeaderTag
  } = data;

  const columnsMarkup = rows.length
    ? rows.map((row, i) => {
        const {
          title,
          thumbnail,
          altText,
          button,
          buttonTarget
        } = row;

        return (
          <div className="row px-0 mb-4 border border-white d-flex align-items-center" key={i}>
            <div className="col-md-2">
              {thumbnail && (
                <div className="text-column-image py-2">
                  <picture>
                    <img
                      src={thumbnail}
                      alt={altText ? altText : ''}
                      aria-hidden={altText ? 'false' : 'true'}
                      className={classNames({
                        'img-fluid': true
                      })}
                    />
                  </picture>
                </div>
              )}
            </div>
            <div className="col-md-6">{title !== '' && <h3>{title}</h3>}</div>
            <div className="col-md-4">
              {button !== '' && (
                <a href={buttonTarget}>
                  <button
                    className={classNames({
                      btn: true,
                      'btn-dark': isDarkText === true,
                      'w-100': true,
                      'my-2': true
                    })}
                  >
                    {button}
                  </button>
                </a>
              )}
            </div>
          </div>
        );
      })
    : null;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    color: textColor
  };

  const HeaderElement = titleHeaderTag ? titleHeaderTag : 'h1';
  const SubHeaderElement = subtitleHeaderTag ? subtitleHeaderTag : 'h2';

  return (
    <div className="container-fluid testimonials" style={inlineStyle}>
      <div
        className={classNames({
          container: true,
          'pt-5': removeTopPadding !== true,
          'pb-5': removeBottomPadding !== true,
          'pt-0': removeTopPadding === true,
          'pb-0': removeBottomPadding === true
        })}
      >
        <div className="row px-0">
          <div
            className={classNames({
              'col-md-12': true,
              'p-0': true,
              'pb-2': true,
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
              'dark-text': isDarkText === true,
              'mb-5': true
            })}
          >
            {functionalHeader === true ? (
              <HeaderElement>{title}</HeaderElement>
            ) : (
              <div className={HeaderElement}>{title}</div>
            )}
            {functionalSubheader === true ? (
              <SubHeaderElement>{subTitle}</SubHeaderElement>
            ) : (
              <div className={SubHeaderElement}>{subTitle}</div>
            )}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        {columnsMarkup}
      </div>
    </div>
  );
};

export default Testimonials;
