import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import Icon from '../../components/Icon/Icon';
import BookSlider from '../../components/BookSlider/BookSlider';
import amazonuk from '../../assets/images/amazonuk-button.png';
import amazonus from '../../assets/images/amazonus-button.png';
import PageNotFound from '../PageNotFound/PageNotFound';

import './BookPage.scss';

class BookPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      books: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const {
      match: { params }
    } = this.props;

    fetch(
      `https://stephengrahamprojects.com/backend/wp-json/api/v1/publications/${params.slug}`
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            books: result,
            loading: false
          });
        },
        error => {
          this.setState({
            error,
            loading: false
          });
        }
      );
  }

  renderPage() {
    const { error, books } = this.state;

    const bookMarkup = books.map((bookData, i) => {
      const {
        title,
        synopsis,
        //heroBanner,
        thumbnail,
        releaseDate,
        reReleaseDate,
        publisher,
        authors,
        editor,
        videoId,
        credit,
        isbn,
        productDimensions,
        pageCount,
        buyAmazon,
        buyAmazonUs,
        metadata = []
      } = bookData;

      const { seoTitle, seoDescription, seoTags } = metadata;

      return (
        <Fragment>
          <div className="container-fluid p-0" key={i}>
            <Helmet
              title={seoTitle ? seoTitle : 'Stephen Graham Projects'}
              meta={[
                {
                  name: 'description',
                  content: seoDescription
                },
                {
                  name: 'keywords',
                  content: seoTags
                }
              ]}
            />
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h1>{title}</h1>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-md-4">
                  <img
                    className="w-100 mb-3"
                    src={thumbnail}
                    alt={`Thumbnail for ${title}`}
                  />
                </div>
                <div className="col-md-8">
                  <dl className="row">
                    {publisher && (
                      <Fragment>
                        <dt className="col-sm-3">Published By:</dt>
                        <dd className="col-sm-9">{publisher}</dd>
                      </Fragment>
                    )}
                    {pageCount && (
                      <Fragment>
                        <dt className="col-sm-3">Paperback:</dt>
                        <dd className="col-sm-9">{pageCount} pages</dd>
                      </Fragment>
                    )}
                    {authors && (
                      <Fragment>
                        <dt className="col-sm-3">Authored By:</dt>
                        <dd className="col-sm-9">{authors}</dd>
                      </Fragment>
                    )}
                    {editor && (
                      <Fragment>
                        <dt className="col-sm-3">Edited By:</dt>
                        <dd className="col-sm-9">{editor}</dd>
                      </Fragment>
                    )}
                    {credit && (
                      <Fragment>
                        <dt className="col-sm-3">Additional Credit:</dt>
                        <dd className="col-sm-9">{credit}</dd>
                      </Fragment>
                    )}
                    {releaseDate && (
                      <Fragment>
                        <dt className="col-sm-3">Release Date:</dt>
                        <dd className="col-sm-9">{releaseDate}</dd>
                      </Fragment>
                    )}
                    {reReleaseDate && (
                      <Fragment>
                        <dt className="col-sm-3">Re-release Date:</dt>
                        <dd className="col-sm-9">{reReleaseDate}</dd>
                      </Fragment>
                    )}
                    {productDimensions && (
                      <Fragment>
                        <dt className="col-sm-3">Product Dimensions:</dt>
                        <dd className="col-sm-9">{productDimensions}</dd>
                      </Fragment>
                    )}
                    {isbn && (
                      <Fragment>
                        <dt className="col-sm-3">ISBN:</dt>
                        <dd className="col-sm-9">{isbn}</dd>
                      </Fragment>
                    )}
                  </dl>
                  <div className="row">
                    <div className="col-sm-12 related-links">
                      <ul className="nav">
                        {buyAmazonUs && (
                          <li className="nav-link pl-0">
                            <a
                              href={buyAmazonUs}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                type="img"
                                src={amazonus}
                                alt={`${title} on Amazon`}
                              />
                            </a>
                          </li>
                        )}
                        {buyAmazon && (
                          <li className="nav-link pl-0">
                            <a
                              href={buyAmazon}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                type="img"
                                src={amazonuk}
                                alt={`${title} on Amazon`}
                              />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid alt-bg p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h3 className="text-white">Synopsis</h3>
                </div>
                <div
                  className="col-md-12 pt-2 text-white"
                  dangerouslySetInnerHTML={{ __html: synopsis }}
                />
              </div>
            </div>
          </div>
          {videoId && (
            <div className="container-fluid p-0">
              <div className="container">
                <div className="row py-5">
                  <div className="col-md-12">
                    <h3>Related Video</h3>
                  </div>
                  <div className="col-md-12 pt-2">
                    <div className="video-wrapper">
                      <iframe
                        title={title}
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen="allowFullScreen"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container-fluid p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h3>More Books</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <BookSlider />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });

    if (error) {
      console.warn('Error fetching modules');
    } else {
      console.log('modules fetched');
      return bookMarkup;
    }
  }

  render() {
    const { books, loading } = this.state;
    if (loading) {
      return null;
    } else {
      return books.length !== 0 ? this.renderPage() : <PageNotFound />;
    }
  }
}

export default BookPage;
