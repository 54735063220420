import React from 'react';
import Sticky from 'react-stickynode';

import Navigation from '../../components/Navigation/Navigation';
import './Header.scss';

class Header extends React.Component {
  render() {
    return (
      <Sticky enabled={true} innerZ={99}>
        <Navigation />
      </Sticky>
    );
  }
}

export default Header;
