// Code Credit https://github.com/PenguinOfWar

import React from 'react';

export default ({ modules = [], UiComponents = {} }) => {
  let modulesArray = [];

  modules.map((module, i) => {
    let ComponentName = module.name;

    console.log(`Component name: ${ComponentName}`);
    /* check if the component exists in the array */
    if (UiComponents[ComponentName]) {
      /* compose component */
      let ComposedComponent = UiComponents[ComponentName];

      /* push it onto the end of the modules array */
      modulesArray.push(
        <ComposedComponent
          key={i}
          {...module}
          components={UiComponents ? UiComponents : {}}
        />
      );
    } else {
      console.warn(
        'src.core.componentComposer.js: Component composition failure. Failed to compose component from module: %s. Skipping...',
        ComponentName
      );
    }

    return true;
  });

  return modulesArray;
};
