import React from 'react';
import classNames from 'classnames';

import './StandardHeroBanner.scss';

const StandardHeroBanner = props => {
  const { data = {} } = props;
  const {
    title,
    subtitle,
    content,
    alignment,
    thumbnail,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText,
    removeTopPadding,
    removeBottomPadding,
    addBottomBorder,
    functionalHeader,
    functionalSubheader,
    titleHeaderTag,
    subtitleHeaderTag
  } = data;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    //backgroundSize: 'cover',
    color: textColor
  };

  const HeaderElement = titleHeaderTag ? titleHeaderTag : 'h1';
  const SubHeaderElement = subtitleHeaderTag ? subtitleHeaderTag : 'h2';

  return (
    <div
      className={classNames({
        'container-fluid': true,
        'standard-hero-banner': true,
        'yellow-border': addBottomBorder === true,
        'right': alignment === 'right'
      })}
      style={inlineStyle}
    >
      <div className="container">
        {alignment === 'left' && (
          <div
            className={classNames({
              row: true,
              'pt-5': removeTopPadding !== true,
              'pb-5': removeBottomPadding !== true,
              'pt-0': removeTopPadding === true,
              'pb-0': removeBottomPadding === true
            })}
          >
            <div
              className={classNames({
                'col-md-6': true,
                'd-flex align-items-center': true,
                'p-0': true
              })}
            >
              {thumbnail && (
                <img
                  className="thumbnail mx-auto d-block"
                  src={thumbnail}
                  alt=""
                  aria-hidden="true"
                />
              )}
            </div>
            <div
              className={classNames({
                'col-md-6': true,
                'pl-4 pr-0 py-4': true,
                'text-left': true,
                'smart-center': true,
                'dark-text': isDarkText === true
              })}
            >
              {functionalHeader === true ? (
                <HeaderElement>{title}</HeaderElement>
              ) : (
                <div className={HeaderElement}>{title}</div>
              )}
              {functionalSubheader === true ? (
                <SubHeaderElement>{subtitle}</SubHeaderElement>
              ) : (
                <div className={SubHeaderElement}>{subtitle}</div>
              )}
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        )}
        {alignment === 'right' && (
          <div
            className={classNames({
              row: true,
              'pt-5': removeTopPadding !== true,
              'pb-5': removeBottomPadding !== true,
              'pt-0': removeTopPadding === true,
              'pb-0': removeBottomPadding === true
            })}
          >
            <div
              className={classNames({
                'col-md-6': true,
                'pl-0 pr-4 py-4': true,
                'text-left': true,
                'smart-center': true,
                'dark-text': isDarkText === true
              })}
            >
              {functionalHeader === true ? (
                <HeaderElement>{title}</HeaderElement>
              ) : (
                <div className={HeaderElement}>{title}</div>
              )}
              {functionalSubheader === true ? (
                <SubHeaderElement>{subtitle}</SubHeaderElement>
              ) : (
                <div className={SubHeaderElement}>{subtitle}</div>
              )}
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div
              className={classNames({
                'col-md-6': true,
                'p-0': true,
                'd-flex align-items-center': true
              })}
            >
              {thumbnail && (
                <img
                  className="thumbnail mx-auto d-block"
                  src={thumbnail}
                  alt=""
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StandardHeroBanner;
