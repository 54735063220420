import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import classNames from 'classnames';

import 'keen-slider/keen-slider.min.css';
import './Gallery.scss';

const Gallery = props => {
  const { data = {} } = props;
  const { slides = [] } = data;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, theSlider] = useKeenSlider({
    initial: 0,
    slideChanged(slide) {
      setCurrentSlide(slide.details().relativeSlide);
    }
  });

  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      }
    },
    [
      slider => {
        function removeActive() {
          slider.slides.forEach(slide => {
            slide.classList.remove('active');
          });
        }

        function addActive(idx) {
          slider.slides[idx].classList.add('active');
        }

        function addClickEvents() {
          slider.slides.forEach((slide, idx) => {
            slide.addEventListener('click', () => {
              if (theSlider.current) theSlider.current.moveToIdx(idx);
            });
          });
        }

        slider.on('created', () => {
          if (!theSlider.current) return;
          addActive(slider.track.details.rel);
          addClickEvents();

          theSlider.current.on('animationStarted', main => {
            removeActive();
            const next = main.animator.targetIdx || 0;
            addActive(main.track.absToRel(next));
            slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
          });
        });
      }
    ]
  );

  function ArrowLeft(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
      <svg
        onClick={props.onClick}
        className={'arrow-gallery arrow-gallery-left' + disabled}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    );
  }

  function ArrowRight(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
      <svg
        onClick={props.onClick}
        className={'arrow-gallery arrow-gallery-right' + disabled}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    );
  }

  const sliderMarkup = slides.map((items, i) => {
    const { image, altText } = items;

    return (
      <img
        key={i}
        className={classNames({
          'keen-slider__slide the-slide': true,
          [`number-slide${i}`]: true,
          'w-100': true
        })}
        src={image}
        alt={altText ?? ''}
      />
    );
  });

  const thumbnailMarkup = slides.map((items, idx) => {
    const { image, altText } = items;

    return (
      <img
        key={idx}
        className={classNames({
          'keen-slider__slide the-slide': true,
          [`number-slide${idx}`]: true,
          'w-100': true,
          active: currentSlide === idx
        })}
        src={image}
        alt={altText ?? ''}
        onClick={() => {
          theSlider.moveToSlideRelative(idx);
        }}
      />
    );
  });

  return (
    <div className="container-fluid my-4">
      <div className="container gallery">
        <div ref={sliderRef} className="keen-slider">
          {sliderMarkup}
        </div>
        <div ref={thumbnailRef} className="keen-slider thumbnail">
          {thumbnailMarkup}
        </div>
        {theSlider && (
          <div>
            <ArrowLeft
              onClick={e => e.stopPropagation() || theSlider.prev()}
              disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={e => e.stopPropagation() || theSlider.next()}
              disabled={currentSlide === theSlider.details().size - 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
