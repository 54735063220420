import React from 'react';
import classNames from 'classnames';

import './StandardText.scss';

const StandardText = props => {
  const { data = {} } = props;
  const {
    title,
    subtitle,
    content,
    alignment,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText,
    removeTopPadding,
    removeBottomPadding,
    functionalHeader,
    functionalSubheader,
    titleHeaderTag,
    subtitleHeaderTag
  } = data;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    color: textColor
  };

  const HeaderElement = titleHeaderTag ? titleHeaderTag : 'h1';
  const SubHeaderElement = subtitleHeaderTag ? subtitleHeaderTag : 'h2';

  return (
    <div className="container-fluid standard-text" style={inlineStyle}>
      <div className="container">
        <div
          className={classNames({
            row: true,
            'pt-5': removeTopPadding !== true,
            'pb-5': removeBottomPadding !== true,
            'pt-0': removeTopPadding === true,
            'pb-0': removeBottomPadding === true
          })}
        >
          <div
            className={classNames({
              'col-md-12': true,
              'p-0': true,
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
              'dark-text': isDarkText === true
            })}
          >
            {functionalHeader === true ? (
              <HeaderElement>{title}</HeaderElement>
            ) : (
              <div className={HeaderElement}>{title}</div>
            )}
            {functionalSubheader === true ? (
              <SubHeaderElement>{subtitle}</SubHeaderElement>
            ) : (
              <div className={SubHeaderElement}>{subtitle}</div>
            )}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardText;
