import React from 'react';
import classNames from 'classnames';

import './VideoMedia.scss';

const VideoMedia = props => {
  const { data = {} } = props;
  const {
    title,
    subtitle,
    content,
    videoId,
    alignment,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText,
    removeTopPadding,
    removeBottomPadding,
    functionalHeader,
    functionalSubheader,
    titleHeaderTag,
    subtitleHeaderTag
  } = data;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    color: textColor
  };

  const HeaderElement = titleHeaderTag ? titleHeaderTag : 'h1';
  const SubHeaderElement = subtitleHeaderTag ? subtitleHeaderTag : 'h2';

  return (
    <div className="container-fluid video-media" style={inlineStyle}>
      <div
        className={classNames({
          container: true,
          'pt-5': removeTopPadding !== true,
          'pb-5': removeBottomPadding !== true,
          'pt-0': removeTopPadding === true,
          'pb-0': removeBottomPadding === true
        })}
      >
        <div className="row">
          <div
            className={classNames({
              'col-md-12': true,
              'p-0': true,
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
              'dark-text': isDarkText === true
            })}
          >
            {functionalHeader === true ? (
              <HeaderElement>{title}</HeaderElement>
            ) : (
              <div className={HeaderElement}>{title}</div>
            )}
            {functionalSubheader === true ? (
              <SubHeaderElement>{subtitle}</SubHeaderElement>
            ) : (
              <div className={SubHeaderElement}>{subtitle}</div>
            )}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="video-wrapper">
              <iframe
                title={title}
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowFullScreen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoMedia;
