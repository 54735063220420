import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import isClient from '../../helpers/isClient';
import Icon from '../Icon/Icon';
import Logo from '../../assets/images/logo.png';
import './Navigation.scss';

class Navigation extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      menu: [],
      loading: false,
      active: false
    };
  }

  componentDidMount() {
    fetch(
      'https://stephengrahamprojects.com/backend/wp-json/wp-api-menus/v2/menus/top-menu/'
    )
      .then(res => res.json())
      .then(
        res => {
          this.setState({
            loading: true,
            menu: res.items
          });
        },
        error => {
          this.setState({
            loading: true,
            error
          });
        }
      );
  }

  toggleNavigation(event) {
    event.preventDefault();
    const { active } = this.state;

    this.setState({
      active: !active
    });
  }

  render() {
    const { error, loading, menu, active } = this.state;
    const menuMarkup = menu.map(item => {
      const { id, title, url } = item;

      return (
        <li key={id} className="nav-item px-3">
          <a href={url}>{title}</a>
        </li>
      );
    });

    if (error) {
      console.error(
        `Error fetching menu from endpoint because ${error.message}`
      );
      return <div>{error.message}</div>;
    } else if (!loading) {
      console.log('Loading navigation data...');
      return (
        <div className="row text-center">
          <div className="loader">Loading...</div>
        </div>
      );
    } else {
      console.log('Menu loaded correctly from endpoint');
      return (
        <MediaQuery query="(max-width: 991px)">
          {matches => {
            return !matches && isClient() ? (
              <div className="container-fluid navigation d-flex align-items-center">
                <div className="container">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4 p-0">
                      <a href="/">
                        <img src={Logo} alt="Stephen Graham Projects" />
                      </a>
                    </div>
                    <div className="col-md-8 p-0">
                      <ul
                        className="nav align-self-center justify-content-end"
                        role="navigation"
                      >
                        {menuMarkup}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="container-fluid navigation d-flex align-items-center">
                  <div className="container px-0">
                    <div className="row d-flex align-items-center">
                      <div className="col-6">
                        <a href="/">
                          <img src={Logo}  alt="Stephen Graham Projects" />
                        </a>
                      </div>
                      <div className="col-6 text-right hamburger">
                        <button
                          className="text-new-grey"
                          onClick={event => this.toggleNavigation(event)}
                        >
                          {!active ? (
                            <Icon type="fa" icon="fa-bars" alt="Open Menu" />
                          ) : (
                            <Icon type="fa" icon="fa-times" alt="Close Menu" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <SlideDown
                  className={classNames({
                    active: active,
                    inactive: !active
                  })}
                >
                  <div className="container-fluid navigation-drop">
                    <div className="container">
                      <div className="row d-flex justify-content-center">
                        <ul className="nav grid-list" role="navigation">
                          {menuMarkup}
                        </ul>
                      </div>
                    </div>
                  </div>
                </SlideDown>
              </Fragment>
            );
          }}
        </MediaQuery>
      );
    }
  }
}

export default Navigation;
