import React from 'react';
import Icon from '../../components/Icon/Icon';
import epglobal from '../../assets/images/ep-global-logo.png';

import './Footer.scss';

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      menu: [],
      loading: false,
      social: []
    };
  }

  componentDidMount() {
    fetch(
      'https://stephengrahamprojects.com/backend/wp-json/wp-api-menus/v2/menus/footer-menu/'
    )
      .then(res => res.json())
      .then(
        res => {
          this.setState({
            menu: res.items,
            loading: true
          });
        },
        error => {
          this.setState({
            error
          });
        }
      );
    fetch(
      'https://stephengrahamprojects.com/backend/wp-json/wp-api-menus/v2/menus/social/'
    )
      .then(res => res.json())
      .then(
        res => {
          this.setState({
            social: res.items,
            loading: true
          });
        },
        error => {
          this.setState({
            error
          });
        }
      );
  }

  currentYear = () => {
    const year = new Date().getFullYear();
    return year;
  };

  render() {
    const { error, menu, loading, social } = this.state;

    const menuMarkup = menu.map(item => {
      const { id, title, children = [] } = item;

      const childrenMarkup = children.map(subItem => {
        const { id, title, url } = subItem;

        return (
          <li key={id} className="nav-item">
            <a href={url}>{title}</a>
          </li>
        );
      });

      return (
        <div key={id} className="col-md-3 px-0">
          <div className="h5 font-weight-bold mt-4 text-white">{title}</div>
          <ul className="nav flex-column">{childrenMarkup}</ul>
        </div>
      );
    });

    const socialMarkup = social.map(socialItem => {
      const { id, title, url } = socialItem;

      return (
        <li key={id} className="nav-link pl-1">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Icon type="fa" icon={`fa-${title}`} alt={`${title}`} />
          </a>
        </li>
      );
    });

    if (error) {
      console.error(
        `Error fetching footer menu from endpoint because ${error.message}`
      );
      return <div>{error.message}</div>;
    } else if (!loading) {
      console.log('Footer items are loading...');
      return null;
    } else {
      console.log('Footer menu loaded correctly from endpoint');
      return (
        <div className="container-fluid footer">
          <div className="container py-4">
            <div className="row">
              {menuMarkup}
              <div className="col-md-3 px-0 py-4">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Icon
                      type="img"
                      src={epglobal}
                      alt="Parent company EP Global"
                      style={{ maxHeight: '60px' }}
                    />
                  </li>
                </ul>
              </div></div>
            <div className="row d-flex align-items-center">
              <div className="col-md-7 pt-4 px-0 text-white">
                &copy; {this.currentYear()} Stephen Graham Projects. All rights
                reserved.
              </div>
              <div className="col-md-5 pt-4 px-0">
                <ul className="social nav float-right h4">{socialMarkup}</ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Footer;
