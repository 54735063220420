import React from 'react';
import Slider from 'react-slick';

//import Icon from '../Icon/Icon';

import './MusicVideoSlider.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class MusicVideoSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    fetch('https://stephengrahamprojects.com/backend/wp-json/api/v1/music')
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const sliderSettings = {
      arrows: true,
      //nextArrow: <i className="fas fa-angle-right"></i>,
      //prevArrow: <i className="fas fa-angle-left"></i>,
      dots: true,
      infinite: false,
      pauseOnHover: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            variableWidth: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false
          }
        }
      ]
    };

    const { error, isLoaded, items } = this.state;

    let contentSliderMarkup;

    contentSliderMarkup = items.map((item, i) => {
      const { title, thumbnail, slug } = item;

      return (
        <div className="w-100 px-2" key={i}>
          <a href={`/music/${slug}`}>
            <img className="w-100" src={thumbnail} alt={title} />
            <div className="text-center">
              <h3>{title}</h3>
            </div>
          </a>
        </div>
      );
    });

    if (error) {
      return <div className="container">Error: {error.message}</div>;
    } else if (!isLoaded) {
      console.log('Loading data to component...');
      return '';
    } else {
      return (
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="slider-container">
                <Slider {...sliderSettings}>{contentSliderMarkup}</Slider>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MusicVideoSlider;
