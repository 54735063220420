/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

import 'keen-slider/keen-slider.min.css';
import './HomePageHeroBanner.scss';

const HomePageHeroBanner = props => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pause, setPause] = useState(false);
  const timer = useRef();
  const [sliderRef, theSlider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    loop: true,
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    }
  });

  useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [sliderRef]);

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && theSlider) {
        theSlider.next();
      }
    }, 4000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, theSlider]);

  const { data = {} } = props;
  const { slides = [] } = data;

  function ArrowLeft(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
      <svg
        onClick={props.onClick}
        className={'arrow arrow--left' + disabled}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    );
  }

  function ArrowRight(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
      <svg
        onClick={props.onClick}
        className={'arrow arrow--right' + disabled}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    );
  }

  const sliderMarkup = slides.map((items, i) => {
    const {
      title,
      content,
      backgroundImage,
      alignment,
      verticalAlignment,
      isBoxed,
      buttonTitle,
      buttonLink
    } = items;

    const sliderStyle = {
      background: backgroundImage ? `url(${backgroundImage})` : false,
      backgroundSize: 'auto 800px',
      width: '100%',
      height: '800px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top'
    };

    return (
      <div
        key={i}
        className={classNames({
          'keen-slider__slide the-slide hero-slide': true,
          [`number-slide${i}`]: true,
          'p-4': true,
          'd-flex': true,
          'align-items-start': verticalAlignment === 'top',
          'align-items-center': verticalAlignment === 'center',
          'align-items-end': verticalAlignment === 'bottom'
        })}
        style={sliderStyle}
      >
        {title !== '' && (
          <div className="container">
            <div
              className={classNames({
                row: true,
                'justify-content-start': alignment === 'left',
                'justify-content-center': alignment === 'center',
                'justify-content-end': alignment === 'right'
              })}
            >
              <div className={classNames({ 'col-md-6': true, boxed: isBoxed })}>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: title
                  }}
                />
                <div>{content}</div>
                {buttonTitle !== ('' || null) && (
                  <a
                    className={classNames({
                      btn: true,
                      'btn-lg': true,
                      'btn-dark': true,
                      'mt-4': true,
                      'mb-0': true
                    })}
                    href={buttonLink}
                  >
                    {buttonTitle}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  });

  const sliderMarkupMobile = slides.map((items, i) => {
    const {
      title,
      content,
      backgroundMobile,
      alignment,
      verticalAlignment,
      isBoxed,
      buttonTitle,
      buttonLink
    } = items;

    const sliderStyleMobile = {
      background: backgroundMobile ? `url(${backgroundMobile})` : false,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    };

    return (
      <div
        key={i}
        className={classNames({
          'keen-slider__slide the-slide': true,
          'p-4': true,
          'd-flex': true,
          'align-items-end': verticalAlignment === 'bottom' || 'middle' || 'top'
        })}
        style={sliderStyleMobile}
      >
        {title !== '' && (
          <div className="container">
            <div
              className={classNames({
                row: true,
                'justify-content-start': alignment === 'left',
                'justify-content-center': alignment === 'center',
                'justify-content-end': alignment === 'right'
              })}
            >
              <div className={classNames({ 'col-md-6': true, boxed: isBoxed })}>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: title
                  }}
                />
                <p>{content}</p>
                {buttonTitle !== ('' || null) && (
                  <a href={buttonLink}>
                    <button
                      className={classNames({
                        btn: true,
                        'btn-lg': true,
                        'btn-outline-grey text-white border': true,
                        'mt-4': true,
                        'mb-0': true
                      })}
                    >
                      {buttonTitle}
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="homepage-hero-banner">
      <div className="navigation-wrapper">
        <MediaQuery minDeviceWidth={992}>
          <div ref={sliderRef} className="keen-slider">
            {sliderMarkup}
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={991}>
          <div ref={sliderRef} className="keen-slider">
            {sliderMarkupMobile}
          </div>
        </MediaQuery>

        {theSlider && (
          <div>
            <ArrowLeft
              onClick={e => e.stopPropagation() || theSlider.prev()}
              disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={e => e.stopPropagation() || theSlider.next()}
              disabled={currentSlide === theSlider.details().size - 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePageHeroBanner;
