import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import Icon from '../../components/Icon/Icon';
import FilmSlider from '../../components/FilmSlider/FilmSlider';
import PageNotFound from '../PageNotFound/PageNotFound';

import tubiImg from '../../assets/images/tubi-big.jpg';
import imdbImg from '../../assets/images/imdb.jpg';
import primeImg from '../../assets/images/primevideo.jpg';

import './FilmPage.scss';

class FilmPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      film: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const {
      match: { params }
    } = this.props;

    fetch(
      `https://stephengrahamprojects.com/backend/wp-json/api/v1/films/${params.slug}`
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            film: result,
            loading: false
          });
        },
        error => {
          this.setState({
            error,
            loading: false
          });
        }
      );
  }

  renderPage() {
    const { error, film } = this.state;

    const filmMarkup = film.map((filmData, i) => {
      const {
        title,
        synopsis,
        // heroBanner,
        thumbnail,
        duration,
        releaseDate,
        producer,
        director,
        editor,
        videoId,
        imdb,
        prime,
        tubi,
        buyAmazon,
        metadata = []
      } = filmData;
      const { seoTitle, seoDescription, seoTags } = metadata;

      return (
        <Fragment>
          <div className="container" key={i}>
            <Helmet
              title={seoTitle ? seoTitle : 'Stephen Graham Projects'}
              meta={[
                {
                  name: 'description',
                  content: seoDescription
                },
                {
                  name: 'keywords',
                  content: seoTags
                }
              ]}
            />

            <div className="row py-5">
              <div className="col-md-12">
                <h1>{title}</h1>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-md-4">
                <img
                  className="w-100 mb-3 border-thick"
                  src={thumbnail}
                  alt={`Thumbnail for ${title}`}
                />
              </div>
              <div className="col-md-8">
                <dl className="row">
                  {producer && (
                    <Fragment>
                      <dt className="col-sm-3">Produced By:</dt>
                      <dd className="col-sm-9">{producer}</dd>
                    </Fragment>
                  )}
                  {director && (
                    <Fragment>
                      <dt className="col-sm-3">Directed By:</dt>
                      <dd className="col-sm-9">{director}</dd>
                    </Fragment>
                  )}
                  {editor && (
                    <Fragment>
                      <dt className="col-sm-3">Edited By:</dt>
                      <dd className="col-sm-9">{editor}</dd>
                    </Fragment>
                  )}
                  {releaseDate && (
                    <Fragment>
                      <dt className="col-sm-3">Release Date:</dt>
                      <dd className="col-sm-9">{releaseDate}</dd>
                    </Fragment>
                  )}
                  {duration && (
                    <Fragment>
                      <dt className="col-sm-3">Duration:</dt>
                      <dd className="col-sm-9">{duration}</dd>
                    </Fragment>
                  )}
                </dl>
                {(imdb || prime || tubi) && (
                  <div className="row">
                    <div className="col-sm-12 related-links">
                      <h3>Available platforms</h3>
                      <ul className="nav">
                        {imdb && (
                          <li className="nav-link pl-0">
                            <a
                              href={imdb}
                              target="_blank"
                              rel="noopener noreferrer"
                              
                            >
                              <Icon
                                type="img"
                                icon={imdbImg}
                                alt={`${title} on IMDb`}
                                style={{ width: '80px' }}
                                className="align-top"
                              />
                            </a>
                          </li>
                        )}
                        {tubi && (
                          <li className="nav-link pl-0">
                            <a
                              href={tubi}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                type="img"
                                icon={tubiImg}
                                alt={`${title} on Tubi`}
                                style={{ width: '80px' }}
                                className="align-top"
                              />
                            </a>
                          </li>
                        )}
                        {prime && (
                          <li className="nav-link pl-0">
                            <a
                              href={prime}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                type="img"
                                icon={primeImg}
                                alt={`${title} on Prime Video`}
                                style={{ width: '80px' }}
                                className="align-top"
                              />
                            </a>
                          </li>
                        )}
                        {buyAmazon && (
                          <li className="nav-link pl-0">
                            <a
                              href={buyAmazon}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                type="fa"
                                icon="fa-amazon"
                                alt={`${title} on Amazon`}
                              />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container-fluid alt-bg p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h3 className="text-white">Synopsis</h3>
                </div>
                <div
                  className="col-md-12 pt-2 text-white"
                  dangerouslySetInnerHTML={{ __html: synopsis }}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h3>Watch</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <div className="video-wrapper">
                    <iframe
                      title={title}
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${videoId}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen="allowFullScreen"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h3>More Films and Documentaries</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <FilmSlider />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });

    if (error) {
      console.warn('Error fetching modules');
    } else {
      console.log('modules fetched');
      return filmMarkup;
    }
  }

  render() {
    const { film, loading } = this.state;
    if (loading) {
      return null;
    } else {
      return film.length !== 0 ? this.renderPage() : <PageNotFound />;
    }
  }
}

export default FilmPage;
