import React, { useState, useEffect } from 'react';

import './AsteroidBanner.scss';

const AsteroidBanner = ({
  id,
  title,
  legalStatement,
  readmoreText,
  readMoreLink,
  backgroundColor,
  textColor,
  buttonTitle,
  siteName
}) => {
  const [visible, setVisible] = useState(true);

  // Accept/reject all cookies
  function acceptCookies(choice) {
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + (choice ? 12 : 1));

    const cookieName = `${siteName}Cookie` ?? 'AsteroidCookie';

    const cookieValue = {
      performance: choice,
      essentials: true,
      analytics: choice,
      expires: expiryDate.toUTCString()
    };

    document.cookie = `${cookieName.replace(/\s/g, '')}=${JSON.stringify(
      cookieValue
    )};expires=${expiryDate.toUTCString()};path=/`;
    setVisible(false);
  }

  // Function to read cookie
  function checkCookie(cookieName) {
    if (!cookieName && cookieName !== undefined) return null;
    cookieName = encodeURIComponent(cookieName).replace(
      /[.*+?^=!:${}()|[\]/\\]/g,
      '\\$&'
    ); //escape regexp characters
    const rxp = new RegExp(`(?:^|;)\\s*${cookieName}=([^;]*)`);
    const theCookie = rxp.exec(document.cookie);
    return theCookie ? decodeURIComponent(theCookie[1]) : null;
  }

  // Checks on mount if cookie exists before updating state to false.
  useEffect(() => {
    const cookieName = `${siteName}Cookie`;

    if (checkCookie(cookieName.replace(/\s/g, ''))) {
      setVisible(false);
    }
  }, [siteName]);

  // Customise banner style
  const bannerStyle = {
    color: textColor ?? '#000',
    backgroundColor: backgroundColor ?? '#fff',
    visibility: visible ? 'visible' : 'hidden'
  };

  return (
    <div
      id={id ?? undefined}
      className="asteroid container-fluid position-fixed fixed-bottom p-4"
      style={bannerStyle}
    >
      <div className="row">
        <div className="col-sm-12 col-md-10 d-flex flex-column justify-content-center">
          <div className="h2 mb-2">{title}</div>
          <p className="mb-sm-1">
            {legalStatement}{' '}
            <a href={readMoreLink} target="_blank" rel="noopener noreferrer">
              {readmoreText ?? 'Read More'}
            </a>
          </p>
        </div>
        <div className="col-sm-12 col-md-2 d-flex flex-column align-items-center justify-content-end">
          <button
            className="btn btn-lg btn-grey w-100 mr-0"
            onClick={() => acceptCookies(true)}
          >
            {buttonTitle}
          </button>
          <button
            className="btn btn-lg btn-outline-grey w-100 mb-0 mr-0"
            onClick={() => acceptCookies(false)}
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default AsteroidBanner;
