import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import componentComposer from '../../core/componentComposer';

import * as UiComponents from '../../components';
import PageNotFound from '../PageNotFound/PageNotFound';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      page: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const {
      match: { params }
    } = this.props;
    console.log(params);
    fetch(
      `https://stephengrahamprojects.com/backend/wp-json/api/v1/pages/${params.slug}`
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            page: result,
            loading: false
          });
        },
        error => {
          this.setState({
            error,
            loading: false
          });
        }
      );
  }

  renderPage() {
    const { error, page } = this.state;

    const pageMarkup = page.map((pageData, i) => {
      const { modules = [], metadata = [] } = pageData;
      const { seoTitle, seoDescription, seoTags } = metadata;

      const ComposedComponents = componentComposer({
        modules,
        UiComponents
      });

      return (
        <div className="modules-container p-0" key={i}>
          <Helmet
            title={seoTitle ? seoTitle : 'Stephen Graham Projects'}
            defaultTitle={'Stephen Graham Projects'}
            meta={[
              {
                name: 'description',
                content: seoDescription
              },
              {
                name: 'keywords',
                content: seoTags
              }
            ]}
          />
          {ComposedComponents}
        </div>
      );
    });

    if (error) {
      console.warn('Error fetching modules');
    } else {
      console.log('modules fetched');
      return <Fragment>{pageMarkup}</Fragment>;
    }
  }

  render() {
    const { page, loading } = this.state;
    if (loading) {
      return null;
    } else {
      return page.length !== 0 ? this.renderPage() : <PageNotFound />;
    }
  }
}

export default Page;
