import React from 'react';

const Home = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">Hello I am a Home Page</div>
      </div>
    </div>
  );
};

export default Home;
