import React from 'react';

import './BookList.scss';

class BookList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      books: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;

    fetch(
      'https://stephengrahamprojects.com/backend/wp-json/api/v1/publications'
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            books: result
          });
        },
        error => {
          this.setState({
            error
          });
        }
      );
  }

  render() {
    const { error, books } = this.state;

    const bookMarkup = books.map((bookData, i) => {
      const { title, slug, thumbnail, shortSynopsis } = bookData;

      return (
        <div key={i} className="col-sm-6 col-md-5 col-lg-4">
          <a href={`/author/${slug}`}>
            <img
              className="mb-3 align-self-center"
              src={thumbnail}
              alt={`Thumbnail for ${title}`}
            />
            <h3>{title}</h3>
          </a>
          <p>{shortSynopsis}</p>
        </div>
      );
    });

    if (error) {
      console.warn('Error fetching films');
    } else {
      console.log('Books fetched');
      return (
        <div className="container-fluid book-directory">
          <div className="container p-0">
            <h2>Full Directory</h2>
            <div className="row py-5">{bookMarkup}</div>
          </div>
        </div>
      );
    }
  }
}

export default BookList;
