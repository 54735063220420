import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import componentComposer from '../../core/componentComposer';

import * as UiComponents from '../../components';
import PageNotFound from '../PageNotFound/PageNotFound';

class CategoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      category: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const {
      match: { params }
    } = this.props;

    fetch(
      `https://stephengrahamprojects.com/backend/wp-json/api/v1/${params.category}/${params.slug}`
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            category: result,
            loading: false
          });
        },
        error => {
          this.setState({
            error,
            loading: false
          });
        }
      );
  }

  renderPage() {
    const { error, category } = this.state;

    const categoryMarkup = category.map((categoryData, i) => {
      const { modules = [], metadata = [] } = categoryData;
      const { seoTitle, seoDescription, seoTags } = metadata;

      const ComposedComponents = componentComposer({
        modules,
        UiComponents
      });

      return (
        <div className="modules-container p-0" key={i}>
          <Helmet
            title={seoTitle ? seoTitle : 'Stephen Graham Projects'}
            //defaultTitle={'Stephen Graham Projects'}
            meta={[
              {
                name: 'description',
                content: seoDescription
              },
              {
                name: 'keywords',
                content: seoTags
              }
            ]}
          />
          {ComposedComponents}
        </div>
      );
    });

    if (error) {
      console.warn('Error fetching modules');
    } else {
      console.log('modules fetched');
      return <Fragment>{categoryMarkup}</Fragment>;
    }
  }

  render() {
    const { category, loading } = this.state;
    if (loading) {
      return null;
    } else {
      return category.length !== 0 ? this.renderPage() : <PageNotFound />;
    }
  }
}

export default CategoryPage;
