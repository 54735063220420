import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import MusicVideoSlider from '../../components/MusicVideoSlider/MusicVideoSlider';
import PageNotFound from '../PageNotFound/PageNotFound';

import './MusicVideoPage.scss';

class MusicVideoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      musicVideo: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const {
      match: { params }
    } = this.props;

    fetch(
      `https://stephengrahamprojects.com/backend/wp-json/api/v1/music/${params.slug}`
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            musicVideo: result,
            loading: false
          });
        },
        error => {
          this.setState({
            error,
            loading: false
          });
        }
      );
  }

  renderPage() {
    const { error, musicVideo } = this.state;

    const musicVideoMarkup = musicVideo.map((musicVideoData, i) => {
      const {
        title,
        producer,
        director,
        editor,
        camera,
        videoId,
        releaseDate,
        metadata = []
      } = musicVideoData;
      const { seoTitle, seoDescription, seoTags } = metadata;

      return (
        <Fragment>
          <div className="container-fluid p-0" key={i}>
            <Helmet
              title={seoTitle ? seoTitle : 'Stephen Graham Projects'}
              meta={[
                {
                  name: 'description',
                  content: seoDescription
                },
                {
                  name: 'keywords',
                  content: seoTags
                }
              ]}
            />
            <div className="container">
              <div className="row pt-5">
                <div className="col-md-12">
                  <h1>{title}</h1>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-md-12 pt-2">
                  <div className="video-wrapper">
                    <iframe
                      title={title}
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${videoId}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen="allowFullScreen"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid alt-bg p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-6">
                  <dl className="row text-white">
                    {producer && (
                      <Fragment>
                        <dt className="col-sm-4">Produced By:</dt>
                        <dd className="col-sm-8">{producer}</dd>
                      </Fragment>
                    )}
                    {director && (
                      <Fragment>
                        <dt className="col-sm-4">Directed By:</dt>
                        <dd className="col-sm-8">{director}</dd>
                      </Fragment>
                    )}
                    {editor && (
                      <Fragment>
                        <dt className="col-sm-4">Edited By:</dt>
                        <dd className="col-sm-8">{editor}</dd>
                      </Fragment>
                    )}
                  </dl>
                </div>
                <div className="col-md-6">
                  <dl className="row text-white">
                    {camera && (
                      <Fragment>
                        <dt className="col-sm-4">Camera Ops:</dt>
                        <dd className="col-sm-8">{camera}</dd>
                      </Fragment>
                    )}
                    {releaseDate && (
                      <Fragment>
                        <dt className="col-sm-4">Released Date:</dt>
                        <dd className="col-sm-8">{releaseDate}</dd>
                      </Fragment>
                    )}
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12">
                  <h3>More Music Videos</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <MusicVideoSlider />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });

    if (error) {
      console.warn('Error fetching modules');
    } else {
      console.log('modules fetched');
      return musicVideoMarkup;
    }
  }

  render() {
    const { musicVideo, loading } = this.state;
    if (loading) {
      return null;
    } else {
      return musicVideo.length !== 0 ? this.renderPage() : <PageNotFound />;
    }
  }
}

export default MusicVideoPage;
