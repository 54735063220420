import React from 'react';
import classNames from 'classnames';

import './QuoteBlock.scss';

const QuoteBlock = props => {
  const { data = {} } = props;
  const {
    quoteText,
    quoteAttributionFrom,
    quoteAttributionName,
    removeBottomPadding,
    removeTopPadding,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText
  } = data;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    color: textColor
  };

  return (
    <div
      className={classNames({
        'container-fluid': true,
        quote: true,
        'quote-dark': isDarkText,
        'pt-5': removeTopPadding !== true,
        'pb-5': removeBottomPadding !== true,
        'pt-0': removeTopPadding === true,
        'pb-0': removeBottomPadding === true
      })}
      style={inlineStyle}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <blockquote>
              <span className="leftquote text-accent" />
              <p className="quoteTitle">{quoteText}</p>
              <p>
                <em>{quoteAttributionName}</em>
                <br />
                <em>{quoteAttributionFrom}</em>
              </p>
              <span className="rightquote text-accent" />
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteBlock;
