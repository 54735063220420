import React from 'react';

import './PageNotFound.scss';

const PageNotFound = () => {
  return (
    <div className="container">
      <div className="text-center p-5">
        <h1 className="text-center">404: Page Not Found</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
