import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { createBrowserHistory as createHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';
import Helmet from 'react-helmet';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import checkCookie from '../../helpers/checkCookie';

import * as UIPages from '../../pages';
import * as UiComponents from '../../components';

import { version } from '../../../package.json';

import './App.scss';

const history = createHistory();

const App = () => {
  return (
    <Fragment>
      {checkCookie('_SGPCookie') &&
        JSON.parse(checkCookie('_SGPCookie')).analytics && (
          <Helmet>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-NHSXT869EK"
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html:
                  'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "G-NHSXT869EK");'
              }}
            />
          </Helmet>
        )}
      <Helmet>
        <meta name="sgp-version" content={version} />
      </Helmet>

      <UiComponents.AsteroidBanner
        title="Cookies on Stephen Graham Projects"
        legalStatement='This website uses cookies to enhance your browsing experience and analyse our traffic. By clicking "Accept All", you consent to our use of cookies.'
        readMoreLink="/privacy-policy"
        buttonTitle="Accept All"
        siteName="_SGP"
      />

      <LazyLoad height={200}>
        <ErrorBoundary FallbackComponent={UIPages.PageNotFound}>
          <Header />
          <Router history={history}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path="/author/:slug" component={UIPages.BookPage} />
              <Route path="/music/:slug" component={UIPages.MusicVideoPage} />
              <Route path="/filmmaker/:slug" component={UIPages.FilmPage} />
              <Route
                path="/:category/:slug"
                component={props => (
                  <UIPages.CategoryPage components={UiComponents} {...props} />
                )}
              />
              <Route
                path="/:slug"
                component={props => (
                  <UIPages.Page components={UiComponents} {...props} />
                )}
              />
              <Route path="*" component={UIPages.PageNotFound} />
            </Switch>
          </Router>
          <Footer />
        </ErrorBoundary>
      </LazyLoad>
    </Fragment>
  );
};

export default App;
