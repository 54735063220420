import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import request from 'superagent';
import Recaptcha from 'react-recaptcha';

const ContactForm = () => {
  const [mailSent, setMailSent] = useState(false);
  const [mailFailed, setMailFailed] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  });

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Your full name is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Your email is required'),
    subject: Yup.string().required('A subject is required'),
    message: Yup.string().required('Your message is required')
  });

  const onSubmit = async values => {
    const uri = 'https://stephengrahamprojects.com/api/submission.php';

    try {
      const response = await request
        .post(uri)
        .set('Content-Type', 'application/json')
        .send({ ...values });

      const { data = {} } = response;
      const { sent } = data;

      if (sent) {
        setMailSent(true);
        setMailFailed(false);
      }
    } catch (err) {
      setMailSent(true);
      console.warn(err);
    }
  };

  /*const redirectUser = () => {
    const targetUrl = '/home';
    window.location.href = targetUrl;
  };*/

  const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
    recaptcha: ''
  };

  return (
    <div className="container-fluid">
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-6 pl-0">
            <h2>Information</h2>
            <p>You can contact Stephen by filling out the form.</p>
            <p>
              <em>
                Stephen Graham Projects will not sell your information to third
                parties. All contact to Stephen Graham Projects will strictly be
                used for communication purposes.
              </em>
            </p>
          </div>
          <div className="col-md-6">
            <Formik
              initialValues={initialValues}
              validationSchema={formSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue }) => {
                return (
                  <Form>
                    <div className="mb-3">
                      <label className="h4" htmlFor="name">
                        Your Name
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        name="name"
                        id="name"
                      />
                      <ErrorMessage
                        className="alert alert-warning mt-2"
                        name="name"
                        component="div"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="h4" htmlFor="email">
                        Your Email
                      </label>
                      <Field
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                      />
                      <ErrorMessage
                        className="alert alert-warning mt-2"
                        name="email"
                        component="div"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="h4" htmlFor="subject">
                        Subject
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        name="subject"
                        id="subject"
                      />
                      <ErrorMessage
                        className="alert alert-warning mt-2"
                        name="subject"
                        component="div"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="h4" htmlFor="message">
                        Message
                      </label>
                      <Field
                        className="form-control"
                        component="textarea"
                        name="message"
                        id="message"
                        rows="4"
                      />
                      <ErrorMessage
                        className="alert alert-warning mt-2"
                        name="message"
                        component="div"
                      />
                    </div>
                    <div className="mb-3">
                      <Recaptcha
                        sitekey="6LfbrQwbAAAAAPn9-9gFt2jerZ7IB9xjyyU0xm0r"
                        render="explicit"
                        theme="light"
                        verifyCallback={response => {
                          setFieldValue('recaptcha', response);
                        }}
                        onloadCallback={() => {
                          console.log('done loading!');
                        }}
                      />
                    </div>
                    <button
                      className="btn btn-outline-grey btn-lg"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit Form
                    </button>
                  </Form>
                );
              }}
            </Formik>
            {mailSent && (
              <div className="alert alert-success mt-2 mb-4">
                Your message was successfully sent.
              </div>
            )}
            {mailFailed && (
              <div className="alert alert-danger mt-2 mb-4">
                There was an error sending your message, please try again.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
