import React from 'react';
import classNames from 'classnames';

import './AnyCode.scss';

const AnyCode = props => {
  const { data = {} } = props;
  const {
    title,
    subtitle,
    content,
    code,
    alignment,
    backgroundColor,
    backgroundImage,
    textColor,
    isDarkText,
    removeTopPadding,
    removeBottomPadding
  } = data;

  const inlineStyle = {
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    color: textColor
  };

  return (
    <div className="container-fluid any-code" style={inlineStyle}>
      <div
        className={classNames({
          container: true,
          'pt-5': removeTopPadding !== true,
          'pb-5': removeBottomPadding !== true,
          'pt-0': removeTopPadding === true,
          'pb-0': removeBottomPadding === true
        })}
      >
        <div className="row">
          <div
            className={classNames({
              'col-md-12': true,
              'p-0': true,
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
              'dark-text': isDarkText === true
            })}
          >
            <div className="h2">{title}</div>
            <div className="h3">{subtitle}</div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: code }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnyCode;
